.add-text-shadow {
  color: black;
  text-shadow: -2px 2px 0 #eeede4, 2px 2px 0 #eeede4, 2px -2px 0 #eeede4,
    -2px -2px 0 #eeede4;
  mix-blend-mode: difference;
}
.pearl-white-button-shadow:enabled {
  box-shadow: 0px 4px #eeede4;
}
.show {
  box-shadow: 0px 4px #eeede4;
}

.orb-mask {
  mask: radial-gradient(circle at 50% 90%, rgba(0, 0, 0, 0) 10%, black 40%);
}

.selected {
  color: black;
  text-shadow: -2px 2px 0 #eeede4, 2px 2px 0 #eeede4, 2px -2px 0 #eeede4,
    -2px -2px 0 #eeede4;
  mix-blend-mode: normal;
}

/* Button Loading on Submit animation */
.loading {
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-left-color: #fff;
  border-top-color: #fff;
  animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
}

/*
 Change bg color in autofill textbox
 You cannot completely remove the background color for autofill inputs,
 so we're hacking around it by throwing in a transition
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-text-primary) !important;
  background-color: transparent;
  transition: background-color 5000000s ease-in-out 0s;
}

.grid-bg {
  @apply laptop:bg-[url('/images/grid--desktop.svg')] bg-[url('/images/grid--mobile.svg')] bg-[length:100%] bg-bottom bg-no-repeat;
}

.background-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.9;

  top: 0;
  left: 0;
}

.card-badge-background {
  background-image: url("/images/mini-card.png");
  background-position: left 0.8rem bottom;
  background-repeat: no-repeat;
  background-size: auto 1.1rem;
  background-origin: border-box;
  background-attachment: local;
  background-color: #222222;
}

@media only screen and (min-device-width: 768px) {
  .clip-path {
    clip-path: inset(55% 44% 30% 44%);
  }
}
.clip-path {
  clip-path: inset(55% 36% 30% 36%);
}
