@keyframes spin {
  to {
    transform: rotate(359deg);
  }
}
@keyframes grow {
  to {
    width: 22px;
    height: 22px;
  }
}

@keyframes scroll-right {
  0% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-50%);
  }
  100% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(50%);
  }
}
