/**
 * Roobert Pro
 * Reg/Med/Bold with no italics
 */

@font-face {
  font-family: "Roobert";
  src: url("/fonts/design-system/RoobertPRO-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Roobert";
  src: url("/fonts/design-system/RoobertPRO-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("/fonts/design-system/RoobertPRO-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

/**
 * Helvetica Monospaced Pro
 * Only Regular, no italics
 */

@font-face {
  font-family: "Helvetica Monospaced Pro";
  src: url("/fonts/design-system/HelveticaMonospacedPro-Rg.woff2")
    format("woff2");
  font-weight: 400;
  font-display: swap;
}

/** 
 * Deprecated Below - Delete when onboarding pages are updated.
 */
@font-face {
  font-family: "Helvetica Now Variable";
  src: url("/fonts/HelveticaNowVar.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Text Bold";
  src: url("/fonts/HelveticaNowText-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Palatino";
  src: url("/fonts/Palatino.ttc");
  font-display: swap;
}
